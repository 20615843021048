body {
    background-color: #F2F2F2;
}
.profile-container {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
}

.student-session {
    /* border: 1px solid black; */
    width: 100%;
    margin: 0 10%;
    display: flex;
}

.student-box{
    flex: 1 1 220px;
    /* border: 1px solid black; */
    background-color: white;
}
a {
    color: black;
}

.resources-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


}

.center-text{
    text-align: center;
}

.book {
    width: 400px;
    margin: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.book-img {
    height: 300px;
    width: 240px;
}
.register-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: 50vh;
    width: 100%;
}

.middle {
    background-color: #843224;
    text-align: center;
    color: white;
}

.lowercase {
  text-transform: lowercase;
  }
body{
    margin: 0;
}
.home-container {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;

}


.grey-font {
    color: #3D3D3D;
}
.intro {
    height: 80vh;
    width: 100%;
}

.col-parent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}
.col-child {
    width: 100%;
    /* border: 2px solid black; */
    flex: 1;
    padding: 3% 3%;

}

.how-it-works {
    height: 100%;
    /* border: 1px solid red; */
    background-color: white;
}

.get-started {
    display: flex;
    flex-direction: column;
    /* border: 1px solid; */
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.center-col {
    /* justify-content: center; */
    align-items: center;
}

.center-row {
    /* justify-content: center; */
    justify-content: center;
}

.center {
    text-align: center;
}
.items {
    display: flex;
    flex-wrap: wrap;
}

.item {
    display: flex;
    flex-direction: column;
    /* padding: 2%; */
    width: 200px;
}

.get-started-icons{
    height: 110px;
}

.reviews {
    /* border: 1px solid red; */
    width: 100%;
}

.img {
    height: 100%;
    min-width: 300px;
    width: 100%;
}

.wrap{
    display: flex;
    flex-wrap: wrap;
    min-width: 100px;
}


@media only screen and (max-width: 600px) {
    .home-container {
      text-align: center;
    }

    .navbar {
        position: fixed;
        bottom: 0px;
        background-color: white;
        width: 100%;
    }

    .item {
        margin: auto;
    }
    .logo {
       height: 10%;
       width: 10%;         
    }
  }
.navbar {
    display: flex;
    /* background-color: aqua; */
    padding: 0 8%;
    padding-top:15px;
    height: 10vh;
    width: 80%;
    /* justify-content: center;
    align-items: center; */
}

.navbar-mobile{
    display: none;
}
.logo {
    width: 130px;
}
.logo-mob {
    width: 80px;
}
ul li{
    list-style: none;
    display: inline;
    margin: 0 10px;
    font-weight:600;
    padding: 5px 7px;
    border: 2px solid #843224;
    border-radius: 8px; 
    background-color: #843224;   
}

a {
    color: white;
    text-decoration: none;
}
.right-nav{
    position: absolute;
    right: 0;
    top:0;
    margin-top:31px;
    margin-right: 10%;
}

.right-nav-links > ul li{
    list-style: none;
    display: inline;
    color: red;
}


@media only screen and (max-width: 1138px) {
    .home-container {
      text-align: center;
    }

    .navbar {
        display: none;
    }

    .hide-me {
        display: none;
    }
    .navbar-mobile {
        visibility: visible; 
        display: flex;
        padding: 0 8%;
        padding-top:15px;
        height: 10vh;
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .mobile-select {
        height: 30px;
        width: 100px;
        /* margin-right: 5px; */
        
    }

    ul li{
        list-style: none;
        display: inline;
        margin: 0 10px;
        font-weight:500;
        padding: 3px 4px;
        border: 2px solid #843224;
        border-radius: 8px; 
        background-color: #843224;   
    }

  }

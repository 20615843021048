.online-activities {
    height: 84.5vh;
    width: 99.8%;
}

.rw-container {
    height: 81vh;
    width: 100%;
}

.select-option{
    width: 100%;
    height: 30px;
}
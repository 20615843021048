
.student-session {
    /* border: 1px solid black; */
    display: flex;
    flex-wrap: wrap;
}

.student-box{
    flex: 1 1 270px;
    margin: 20px;
    /* border: 1px solid black; */
    background-color: #843224;
    color: white;
    text-align: center;

}
.row {
    display: flex;
    flex-direction: row;
}

.white {
    color: white;
}
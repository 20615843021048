.lesson-report-create-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    width: 100%;
}

.hidden {
    display: none;
}
* {
    color: black
}
.parentedit-container {
    padding: 0 10%;
}
.buttonStyle{
    background-color: #843224; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 2px;
    border-radius: 20%;
}

.buttonStyleSmall{
    background-color: #843224; /* Green */
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin: 2px;
    border-radius: 20%;
}

.hide {
    display: none;
}

ul > li {
    margin: 20px;
}

h2 {
    font-weight: bold;
    font-size: 1.5em;
}

.user-info {
    display: flex;
    justify-content: space-around;
}
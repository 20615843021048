.lesson-report-container {
    /* border: 1px solid red; */
    margin: 0 10%;
    
}

.lesson-reports {
    /* border: 1px solid black; */
    width: 100%;
}

.inline {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    justify-content: space-between;

}

table.center {
    margin-left: auto;
    margin-right: auto;
}

td, th{
    padding: 15px;
    border: 1px solid #ddd;
}

.mobile {
    visibility: hidden;
}

@media only screen and (max-width: 600px) {
    .mobile {
        visibility: visible
    }
    
    .desktop {
        display: none;
    
    }
  }